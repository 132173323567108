import { BooleanField, DateField, ReferenceField, TextField } from "react-admin";
import ShowBase from "../BaseModel/Show";

const ShowForm = (props: any) => {
  return (
    <ShowBase {...props}>
      <BooleanField source="enabled" />
      <ReferenceField source="connection" reference="connection" link="show">
        <TextField source="name" />
      </ReferenceField>
      <TextField source="schedule_expression" />
      <TextField source="regex" />
      <TextField source="path" />
      <ReferenceField source="created_by" reference="user" link="show">
        <TextField source="name" />
      </ReferenceField>
      <DateField source="created_at" showTime={true} />
      <ReferenceField source="updated_by" reference="user" link="show">
        <TextField source="name" />
      </ReferenceField>
      <DateField source="updated_at" showTime={true} />
    </ShowBase>
  );
};

export default ShowForm;
