import {
  BooleanInput,
  ReferenceInput,
  required,
  SaveButton,
  SelectInput,
  TextInput,
  Toolbar,
} from "react-admin";
import { scheduleValidation } from "..";
import EditBase from "../BaseModel/Edit";
const sortable = {field : "name", order : "ASC"}

const UpdateToolbar = (props: any) => (
  <Toolbar {...props}>
    <SaveButton
      transform={(data) => {
        const { id, name, created_at, created_by, updated_at, updated_by, ...rest } = data;
        return rest;
      }}
      submitOnEnter={false}
    />
  </Toolbar>
);

const EditForm = (props: any) => (
  <EditBase {...props} toolbar={<UpdateToolbar />}>
    <BooleanInput source="enabled" defaultValue={true} />
    <ReferenceInput source="connection" reference="connection" validate={[required()]}
        sort={sortable}
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
    <TextInput source="schedule_expression" validate={[required(), scheduleValidation]} />
    <TextInput source="path" />
    <TextInput source="regex" />
  </EditBase>
);

export default EditForm;
