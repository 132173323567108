import { Fragment } from "react";
import { Button, useRefresh, useNotify, useUnselectAll, useMutation, useTranslate } from "react-admin";
import { useShareableDeleteStepState } from "./SharedState";
import { useBetween } from "use-between";
import Delete from "@material-ui/icons/Delete";

type DeleteWithCheckingType = "connection" | "application";

const BulkDeleteWithChecking = ({
  selectedIds,
  resource,
}: {
  selectedIds: string[];
  resource: DeleteWithCheckingType;
}) => {
  const { deleteStep, updateDeleteStep } = useBetween(useShareableDeleteStepState);
  const refresh = useRefresh();
  const notify = useNotify();
  const unselectAll = useUnselectAll();
  const notificationDurationInMs = 30000;
  const translate = useTranslate();
  const [deleteChecking] = useMutation(
    {
      type: "deleteWithChecking",
      resource: resource,
      payload: {
        ids: selectedIds,
      },
    },
    {
      onSuccess: (results) => {
        updateDeleteStep('DELETE');
        refresh(true);
        if (results?.data?.undeleted?.length > 0) {
          notify(
            `resources.${resource}.deleteActions.unDeleted`,
            {
              type: "warning",
              messageArgs: {
                undeletedCount: results.data.undeleted.length,
                deletedCount: results.data.deleted?.length ?? 0
              },
              autoHideDuration: notificationDurationInMs
            });
        }

        if (results?.data?.deleted?.length > 0) {
          notify(`resources.${resource}.deleteActions.allDeleted`, { type: "success", autoHideDuration: notificationDurationInMs });
        }
        unselectAll(resource);
      },
      onFailure: () =>
        notify(`resources.${resource}.deleteActions.error`, { type: "error", autoHideDuration: notificationDurationInMs }),
    }
  );

  const startDeletingProcess = () => {
    updateDeleteStep('DELETING');
    deleteChecking();
  }

  return (
    <Fragment>
      <Button
        label={translate(deleteStep === 'DELETE' ? `resources.${resource}.deleteActions.deleteLabel` : `resources.${resource}.deleteActions.deletingLabel`)}
        disabled={deleteStep !== 'DELETE'}
        onClick={() => startDeletingProcess()}>
        <Delete />
      </Button>
    </Fragment>
  );
};

export default BulkDeleteWithChecking;
