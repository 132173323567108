import "./App.css";
import packageJson from "../package.json";
import { Admin, Resource, useTranslate } from "react-admin";

import { Amplify } from "@aws-amplify/core";

import themeReducer from "@/Reducers/themeReducer";
import nextTokenReducer from "@/Reducers/nextTokenReducer";
import resetCacheReducer from "@/Reducers/resetCacheReducer";

import customRoutes from "@/routes";

import ApiProvider from "@/Providers/ApiProvider";
import i18nProvider from "@/Settings/i18n";
import { buildAuthProvider } from "@/Providers/AuthProvider";

import { Layout } from "@/Components/Layout";
import Dashboard from "@/Components/Dashboard";

import Models from "@/Components/Models";
import Auth from "@aws-amplify/auth";

import { getAuthorizedPage, grantAccess } from "./Settings/roles";

const {
  REACT_APP_AWS_REGION,
  REACT_APP_IDENTITY_POOL_ID,
  REACT_APP_USER_POOL_ID,
  REACT_APP_USER_POOL_WEB_CLIENT_ID,
  REACT_APP_API_URL,
  REACT_APP_COGNITO_DOMAIN,

} = process.env;

const redirectUrl = `${window.location.protocol}//${window.location.hostname}${(window.location.port) ? `:${window.location.port}` : ""}`;

Amplify.configure({
  Auth: {
    region: REACT_APP_AWS_REGION,
    identityPoolId: REACT_APP_IDENTITY_POOL_ID,
    userPoolId: REACT_APP_USER_POOL_ID,
    userPoolWebClientId: REACT_APP_USER_POOL_WEB_CLIENT_ID,
    mandatorySignIn: true,
  },
  oauth: {
    domain: REACT_APP_COGNITO_DOMAIN!.replace("https://", ""),
    scope: ["email", "profile", "openid"],
    redirectSignIn: redirectUrl,
    redirectSignOut: redirectUrl,
    responseType: "code",
    state: "STATE",
  },
  API: {
    endpoints: [
      {
        name: "api",
        endpoint: REACT_APP_API_URL,
        region: REACT_APP_AWS_REGION,
        custom_header: async () => {
          return {
            Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
            ClientTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          };
        },
      },
    ],
  },
});

const App = () => {
  const translate = useTranslate();
  return (
    <Admin
      title={packageJson.name}
      dataProvider={ApiProvider()}
      initialState={{
        theme: localStorage.getItem("theme") || "light",
      }}
      customReducers={{
        theme: themeReducer,
        nextToken: nextTokenReducer,
        resetCache: resetCacheReducer,
      }}
      customRoutes={customRoutes}
      i18nProvider={i18nProvider}
      authProvider={buildAuthProvider()}
      layout={Layout}
      dashboard={Dashboard}
      disableTelemetry
      loginPage={false}
    >
      {(permissions) => [
        Object.keys(Models).map((key) =>
          grantAccess(permissions, key) ? (
            <Resource
              key={key}
              name={key}
              {...getAuthorizedPage(permissions, { ...Models[key] })}
              options={{ label: translate(`resources.${key}.name`), roles: permissions }}
            />
          ) : (
            <></>
          )
        ),
      ]}
    </Admin>
  );
};

export default App;
