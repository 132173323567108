/**
 * File with helper functions for parsing cron expressions
 *
 * Dump from AWS CloudWatch Console (https://console.aws.amazon.com/cloudwatch/home) at 2022-02-22T09:00:00.000Z
 *
 * /!\ Don't edit this file /!\
 */

/* eslint-disable no-sequences */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/no-unused-expressions */
// @ts-nocheck
function Rd(e, t) {
  for (var n = 0; n < t.length; n++) {
    var a = t[n];
    (a.enumerable = a.enumerable || !1),
      (a.configurable = !0),
      "value" in a && (a.writable = !0),
      Object.defineProperty(e, a.key, a);
  }
}
function Od(e, t, n) {
  return (
    t in e
      ? Object.defineProperty(e, t, {
          value: n,
          enumerable: !0,
          configurable: !0,
          writable: !0,
        })
      : (e[t] = n),
    e
  );
}
function xd(e, t) {
  (null == t || t > e.length) && (t = e.length);
  for (var n = 0, a = new Array(t); n < t; n++) a[n] = e[n];
  return a;
}
function Id(e) {
  return new RegExp("".concat(e, "(\\w*)"));
}
function Dd(e, t) {
  var n = e ? e.toUpperCase() : "";
  return (
    t.forEach(function (e) {
      e = (t = (function (e, t) {
        return (
          (function (e) {
            if (Array.isArray(e)) return e;
          })(e) ||
          (function (e, t) {
            var n = e && (("undefined" != typeof Symbol && e[Symbol.iterator]) || e["@@iterator"]);
            if (null != n) {
              var a,
                r,
                i = [],
                o = !0,
                s = !1;
              try {
                for (
                  n = n.call(e);
                  !(o = (a = n.next()).done) && (i.push(a.value), !t || i.length !== t);
                  o = !0
                );
              } catch (e) {
                (s = !0), (r = e);
              } finally {
                try {
                  o || null == n.return || n.return();
                } finally {
                  if (s) throw r;
                }
              }
              return i;
            }
          })(e, t) ||
          (function (e, t) {
            if (e) {
              if ("string" == typeof e) return xd(e, t);
              var n = Object.prototype.toString.call(e).slice(8, -1);
              return "Map" === (n = "Object" === n && e.constructor ? e.constructor.name : n) ||
                "Set" === n
                ? Array.from(e)
                : "Arguments" === n || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)
                ? xd(e, t)
                : void 0;
            }
          })(e, t) ||
          (function () {
            throw new TypeError(
              "Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."
            );
          })()
        );
      })(e, 2))[0];
      var t = t[1];
      n = n.replace(Id(e), t);
    }),
    n
  );
}
function Ld(e, t, n) {
  return (
    !(n < 1 || 31 < n) &&
    (n = new Date(e, t - 1, n)).getMonth() === t - 1 &&
    0 < n.getDay() &&
    n.getDay() < 6
  );
}
function zd(e, t, n) {
  if ("?" === e) return [];
  if ("L" === e) return ["L"];
  if (Nd.test(e)) return [parseInt(e, 10)];
  if ("LW" === e) return ["LW"];
  if (e.startsWith("L")) return ["L", parseInt(e.substring(1), 10)];
  if (e.endsWith("L")) return ["L", parseInt(e.substring(0, e.length - 1), 10)];
  if (e.endsWith("W")) return ["W", parseInt(e.substring(0, e.length - 1), 10)];
  if (e.includes("#")) return ["#", parseInt(e.split("#")[0], 10), parseInt(e.split("#")[1], 10)];
  var a = [];
  if ("*" === e) {
    for (var r = t; r <= n; ) a.push(r), (r += 1);
    return a;
  }
  if (e.includes(","))
    return (
      e.split(",").forEach(function (e) {
        a = a.concat(zd(e, t, n));
      }),
      a.sort(function (e, t) {
        return e - t;
      })
    );
  if (e.includes("/")) {
    if ("*" === (c = e.split("/"))[0]) c[0] = t.toString();
    else if (c[0].includes("-")) {
      var i = c[0].split("-");
      return zd("".concat(i[0], "/").concat(c[1]), parseInt(i[0], 10), (i = parseInt(i[1], 10)));
    }
    var o = parseInt(c[0], 10),
      s = parseInt(c[1], 10);
    if (0 < s) for (; o <= n; ) a.push(o), (o += s);
    else a.push(o);
    return a;
  }
  if (e.includes("-")) {
    var c = e.split("-"),
      u = ((e = parseInt(c[0], 10)), parseInt(c[1], 10));
    if (e <= u) for (var l = e; l <= u; l += 1) a.push(l);
    else {
      for (var d = t; d <= u; d += 1) a.push(d);
      for (var p = e; p <= n; p += 1) a.push(p);
    }
  }
  return a;
}
var Nd = /^\d+$/,
  Bd = /^((\d+,)+\d+|(\d+\/\d+)|(\d+-(\d+|\d+\/\d+))|\d+|(\*|\*\/\d+))$/,
  Fd = /^((\d{4},)+\d{4}|(\d{4}(\/|-)\d{4})|\d{4}|(\*|(\*(\/\d+)+))|(\d{4}\/\d+))$/,
  jd = /^((\d+,)+\d+|(\d+(\/|-)\d+)|\d+|(\*|(\*(\/\d+)+))|\?|(L(-\d+)?)|\d+W|LW)$/,
  Md = /^((\d+,)+\d+|(\d+(\/|-)\d+)|\d+|(\*|(\*(\/\d+)+))|\?|\d?L|(L-\d+)|(\d+#\d+))$/,
  qd = [
    ["JAN", "1"],
    ["FEB", "2"],
    ["MAR", "3"],
    ["APR", "4"],
    ["MAY", "5"],
    ["JUN", "6"],
    ["JUL", "7"],
    ["AUG", "8"],
    ["SEP", "9"],
    ["OCT", "10"],
    ["NOV", "11"],
    ["DEC", "12"],
  ],
  Wd = [
    ["SUN", "1"],
    ["MON", "2"],
    ["TUE", "3"],
    ["WED", "4"],
    ["THU", "5"],
    ["FRI", "6"],
    ["SAT", "7"],
  ],
  Ud = "minutes",
  Vd = "hours",
  Gd = "dayOfMonth",
  Hd = "month",
  Kd = "dayOfWeek",
  Qd = "year",
  Yd = [Ud, Vd, Gd, Hd, Kd, Qd],
  Zd = (function () {
    function e(t) {
      !(function (e, t) {
        if (!(e instanceof t)) throw new TypeError("Cannot call a class as a function");
      })(this, e),
        Od(this, "minutes", void 0),
        Od(this, "hours", void 0),
        Od(this, "daysOfMonth", void 0),
        Od(this, "months", void 0),
        Od(this, "daysOfWeek", void 0),
        Od(this, "years", void 0);
      try {
        this.parse(t);
      } catch (t) {
        return null;
      }
    }
    var t, n, a;
    return (
      (t = e),
      (a = [
        {
          key: "isValidPart",
          value: function (t, n) {
            if (0 < t.indexOf(","))
              return t.split(",").every(function (t) {
                return t.indexOf("#") < 0 && e.isValidPart(t, n);
              });
            if (
              !(function (e, t) {
                switch (t) {
                  case Ud:
                  case Vd:
                    return Bd.test(e);
                  case Gd:
                    return jd.test(e);
                  case Hd:
                    return Bd.test(Dd(e, qd));
                  case Kd:
                    return Md.test(Dd(e, Wd));
                  case Qd:
                    return Fd.test(e);
                  default:
                    return !1;
                }
              })(t, n)
            )
              return !1;
            if ("*" === t) return !0;
            if (!(("?" !== t && "L" !== t) || (n !== Gd && n !== Kd))) return !0;
            if (
              !Nd.test(t) &&
              n === Hd &&
              qd.some(function (e) {
                return Id(e[0]).test(t.toUpperCase());
              })
            )
              return !0;
            if (
              !Nd.test(t) &&
              n === Kd &&
              Wd.some(function (e) {
                return Id(e[0]).test(t.toUpperCase());
              })
            )
              return !0;
            if (t.endsWith("W")) {
              var a = t.toString().substring(0, t.indexOf("W"));
              return ("L" === a || Nd.test(a)) && e.isValidPart(a, n);
            }
            if (t.startsWith("L"))
              return (
                (a = t.toString().substr(1)).startsWith("-") && (a = a.substr(1)),
                Nd.test(a) && e.isValidPart(a, n)
              );
            if (t.endsWith("L")) {
              var r = t.toString().substring(0, t.indexOf("L"));
              return Nd.test(r) && e.isValidPart(r, n);
            }
            if (n === Kd && -1 < t.indexOf("#"))
              return (
                !(2 < (r = t.split("#")).length) &&
                !(!e.isValidPart(r[0], n) || !Nd.test(r[1])) &&
                1 <= (r = parseInt(r[1], 10)) &&
                r <= 5
              );
            try {
              if (Nd.test(t)) {
                var i = parseInt(t, 10);
                switch (n) {
                  case Ud:
                    return 0 <= i && i <= 59;
                  case Vd:
                    return 0 <= i && i <= 23;
                  case Gd:
                    return 1 <= i && i <= 31;
                  case Hd:
                    return 1 <= i && i <= 12;
                  case Kd:
                    return 1 <= i && i <= 7;
                  case Qd:
                    return 1970 <= i && i <= 2199;
                  default:
                    return !1;
                }
              }
              if (-1 < t.indexOf(",")) {
                var o = t.split(",");
                return (
                  (n !== Kd ||
                    !o.some(function (e) {
                      return -1 < e.indexOf("#");
                    })) &&
                  o.every(function (t) {
                    return e.isValidPart(t, n);
                  })
                );
              }
              if (-1 < t.indexOf("/")) {
                var s = t.split("/");
                return (
                  !(2 < s.length) &&
                  e.isValidPart(s[0], n) &&
                  Nd.test(s[1]) &&
                  (n === Qd ? 0 < parseInt(s[1], 10) : e.isValidPart(s[1], n))
                );
              }
              if (-1 < t.indexOf("-")) {
                var c = t.split("-");
                return e.isValidPart(c[0], n) && e.isValidPart(c[1], n);
              }
            } catch (a) {
              return !1;
            }
            return !1;
          },
        },
        {
          key: "hasOnlyOneOfDayOfWeekAndDayOfMonthSpecified",
          value: function (e) {
            return !(("?" !== e[2] && "?" !== e[4]) || ("?" === e[2] && "?" === e[4]));
          },
        },
        {
          key: "isValid",
          value: function (t) {
            if (!t) return !1;
            if (6 !== (t = t.split(" ")).length) return !1;
            try {
              return (
                e.hasOnlyOneOfDayOfWeekAndDayOfMonthSpecified(t) &&
                t.every(function (t, n) {
                  return e.isValidPart(t, Yd[n]);
                })
              );
            } catch (t) {
              return !1;
            }
          },
        },
      ]),
      (n = [
        {
          key: "parse",
          value: function (e) {
            (e = e.split(" ")),
              (this.minutes = zd(e[0], 0, 59)),
              (this.hours = zd(e[1], 0, 23)),
              (this.daysOfMonth = zd(e[2], 1, 31)),
              (this.months = zd(Dd(e[3], qd), 1, 12)),
              (this.daysOfWeek = zd(Dd(e[4], Wd), 1, 7)),
              (this.years = zd(e[5], 1970, 2199));
          },
        },
        {
          key: "nextInternal",
          value: function (e, t) {
            var n = 2 < arguments.length && void 0 !== arguments[2] ? arguments[2] : 0;
            if (100 === n) return null;
            var a = e.getUTCFullYear(),
              r = e.getUTCMonth() + 1,
              i = e.getUTCDate(),
              o = e.getUTCHours(),
              s = e.getUTCMinutes(),
              c = this.years.find(function (e) {
                return a <= e;
              });
            if (!c) return null;
            var u = this.months.find(function (e) {
              return (c === a ? r : 1) <= e;
            });
            if (!u)
              return this.nextInternal(
                new Date(Date.UTC(t.getUTCFullYear() + 1, 0, 1, 0, 0)),
                t,
                n + 1
              );
            var l = c === a && u === r;
            0 === (e = this.daysOfMonth).length
              ? (e = (function (e, t, n) {
                  for (var a = [], r = 0, i = 1; i <= 31; i += 1) {
                    var o = new Date(e, t - 1, i);
                    if (o.getMonth() !== t - 1) break;
                    if ("L" === n[0]) {
                      if (
                        n[1] === o.getDay() + 1 &&
                        new Date(o.getTime() + 6048e5).getMonth() !== o.getMonth()
                      )
                        return [i];
                    } else if ("#" === n[0]) {
                      if ((n[1] === o.getDay() + 1 && (r += 1), n[2] === r)) return [i];
                    } else n.includes(o.getDay() + 1) && a.push(i);
                  }
                  return a;
                })(c, u, this.daysOfWeek))
              : "LW" === e[0]
              ? (e = (function (e, t) {
                  for (var n = 31; 26 <= n; --n)
                    if (new Date(e, t - 1, n).getMonth() === t - 1 && Ld(e, t, n)) return [n];
                  throw new Error("getDaysOfMonthForLW - should not happen");
                })(c, u))
              : "L" === e[0]
              ? ((d = (function (e, t) {
                  for (var n = 31; 28 <= n; --n)
                    if (new Date(e, t - 1, n).getMonth() === t - 1) return [n];
                  throw new Error("getDaysOfMonthForL - should not happen");
                })(c, u)),
                (e = 2 === e.length ? [d[0] + Number.parseInt(e[1], 10)] : d))
              : "W" === e[0] &&
                (e = (function (e, t, n) {
                  var a = [0, 1, -1, 2, -2].find(function (a) {
                    return Ld(e, t, n + a);
                  });
                  if (void 0 === a) throw new Error("getDaysOfMonthForW - should not happen");
                  return [n + a];
                })(c, u, e[1]));
            var d = (e = e.filter(function (e) {
              return (t = u), new Date(c, t - 1, e).getMonth() === t - 1;
              var t;
            })).find(function (e) {
              return (l ? i : 1) <= e;
            });
            if (!d) return this.nextInternal(new Date(Date.UTC(c, u, 1, 0, 0)), t, n + 1);
            var p = l && d === i,
              m = this.hours.find(function (e) {
                return (p ? o : 0) <= e;
              });
            return void 0 === m
              ? this.nextInternal(new Date(Date.UTC(c, u - 1, d + 1, 0, 0)), t, n + 1)
              : void 0 ===
                (e = this.minutes.find(function (e) {
                  return (p && m === o ? s : 0) <= e;
                }))
              ? this.nextInternal(new Date(Date.UTC(c, u - 1, d, m + 1, 0)), t, n + 1)
              : new Date(Date.UTC(c, u - 1, d, m, e));
          },
        },
        {
          key: "next",
          value: function () {
            var e = 0 < arguments.length && void 0 !== arguments[0] ? arguments[0] : new Date();
            e = new Date(6e4 * (Math.floor(e.getTime() / 6e4) + 1));
            return this.nextInternal(e, e, 0);
          },
        },
      ]) && Rd(t.prototype, n),
      a && Rd(t, a),
      e
    );
  })();

const isValidExpression = (value) =>
  (value.startsWith("cron(") || value.startsWith("rate(")) && value.endsWith(")");
const isValidCron = (value) => Zd.isValid(value.replace("cron(", "").replace(")", ""));
const isValidRate = (value) =>
  /^rate\((?:1\s(?:hour|minute|day)|(?:[2-9]|[1-9][0-9]+)\s(?:hours|minutes|days))\)$/gi.test(
    value
  );

export { isValidExpression, isValidCron, isValidRate };
