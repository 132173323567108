import { Route } from "react-router-dom";
import Settings from "@/Settings";
import Overview from "@/Components/Models/Configuration/Overview";
import {
  EnableOpenWindowAction,
  EnableCopyWindowAction,
  EnableExploreActionsModels,
} from "@/Components/Models";
import Copy from "@/Components/Models/Configuration/Copy";
import Explore from "@/Components/Models/Connection/Explore";

const routesExplore = EnableExploreActionsModels.map((resource) => {
  return <Route exact path={`/${resource}/explore`} component={Explore} />;
});
const routesOverview = EnableOpenWindowAction.map((resource) => {
  return <Route exact path={`/${resource}/:id/overview`} component={Overview} />;
});

const routesCopy = EnableCopyWindowAction.map((resource) => {
  return <Route exact path={`/copy/${resource}/:id`} component={Copy} />;
});

const routes = [
  <Route exact path="/settings" render={() => <Settings />} />,
  ...routesExplore,
  ...routesOverview,
  ...routesCopy,
  <Route exact path={`/copy/configuration/:id/`} component={Copy} />,
  <Route exact path={`/copy/configuration/:id/1`} component={Copy} />,
];

export default routes;
