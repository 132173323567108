import { AuthProvider as AuthProviderInterface } from "ra-core";
import { Auth } from "@aws-amplify/auth";
import { READONLY_ROLE } from "@/Settings/roles";

export interface AuthProviderOptions {
  authGroups?: string[];
}

const defaultOptions = {
  authGroups: [],
};

class AuthProvider {
  public authGroups: string[];

  public constructor(options?: AuthProviderOptions) {
    this.authGroups = options?.authGroups || defaultOptions.authGroups;
  }

  public logout = (): Promise<any> => {
    return Auth.signOut();
  };

  public checkAuth = async (): Promise<void> => {
    let user;
    try {
      user = await Auth.currentAuthenticatedUser();
    } catch (error) {
      await Auth.federatedSignIn({
        customProvider: "Arkema",
      });
    }

    if (this.authGroups.length === 0) {
      return;
    }

    const userGroups = user.getSession().getAccessToken().decodePayload()["cognito:groups"];

    if (!userGroups) {
      throw new Error("Unauthorized");
    }

    for (const group of userGroups) {
      if (this.authGroups.includes(group)) {
        return;
      }
    }

    throw new Error("Unauthorized");
  };

  public checkError = (): Promise<void> => {
    return Promise.resolve();
  };

  public getPermissions = async (): Promise<string[]> => {
    const session = await Auth.currentSession();
    const groups = session.getAccessToken().decodePayload()["cognito:groups"];
    //default to ReadOnly
    groups.push(READONLY_ROLE);
    return groups ? Promise.resolve(groups) : Promise.reject();
  };

  public getIdentity = async (): Promise<any> => {
    const { username } = await Auth.currentAuthenticatedUser();
    return Promise.resolve({
      id: username,
      fullName: username,
    });
  };
}

export function buildAuthProvider(options?: AuthProviderOptions): AuthProviderInterface {
  const authProvider = new AuthProvider(options);

  return {
    login: () => {
      return Promise.reject();
    },
    logout: authProvider.logout,
    checkAuth: authProvider.checkAuth,
    checkError: authProvider.checkError,
    getPermissions: authProvider.getPermissions,
    getIdentity: authProvider.getIdentity,
  };
}
