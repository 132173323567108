import { Button, useNotify, useMutation, ButtonProps, useTranslate } from "react-admin";
import SpeedIcon from "@material-ui/icons/Speed";
import { ReactElement } from "react";
import { Record, useResourceContext } from "ra-core";

interface Props {
  basePath?: string;
  icon?: ReactElement;
  label?: string;
  record?: Record;
  scrollToTop?: boolean;
}

type TestButtonProps = Props & ButtonProps;

const TestButton = (props: TestButtonProps) => {
  const { icon = <SpeedIcon />, label = "common.actions.test.label", record, ...rest } = props;
  const resource = useResourceContext();
  const translate = useTranslate();

  const notify = useNotify();
  const [testResource] = useMutation(
    {
      type: "test",
      resource: resource,
      payload: {
        id: record?.id,
      },
    },
    {
      onSuccess: (result) => {
        let db = result.data.data;
        notify("common.actions.test.success", {
          type: "info",
          messageArgs: {
            id: record?.name,
            connectivityTest: db.connectivityTest ? "OK" : "KO",
            listingFile: db.listingFile ? "OK" : "KO",
          },
        });
      },
      onFailure: (error) => {
        let detail;
        if (error.detail instanceof Object) {
          detail = error.detail.errorMessage;
        } else if (typeof error.detail === "string") {
          detail = error.detail;
        } else if (!error.detail) {
          detail = translate("common.actions.test.timeout");
        }
        notify("common.actions.test.failure", {
          type: "warning",
          messageArgs: { id: record?.id, error: detail },
        });
      },
    }
  );

  return (
    <Button label={label} onClick={() => testResource()} {...(rest as any)}>
      {icon}
    </Button>
  );
};

export default TestButton;
