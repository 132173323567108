import { useMemo } from "react";
import { useRedirect, useRefresh } from "react-admin";
import { useLocation } from "react-router-dom";

export function useSearchParams() {
  const { search } = useLocation();
  return useMemo(() => {
    const x = new URLSearchParams(search);
    return x;
  }, [search]);
}

export function useRedirectWithParams(resource: string, params: Array<Array<string>>) {
  const redirect = useRedirect();
  const refresh = useRefresh();
  return () => {
    const urlParams = new URLSearchParams(params);
    redirect(`/${resource}?${urlParams.toString()}`);
    refresh();
  };
}
