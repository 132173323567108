import { useState } from "react";
import { useBetween } from "use-between";

export const useShareableState = () => {
  const [listChecked, updateListChecked] = useState<string[]>([]);
  return {
    listChecked,
    updateListChecked,
  };
};

export const useSharedListChecked = () => useBetween(useShareableState);
