import { ConditionalInput } from "@/Components/ConditionalForm";
import {
  ArrayInput,
  BooleanInput,
  required,
  SimpleFormIterator,
  TextInput,
  useTranslate,
} from "react-admin";
import { scheduleValidation } from "..";
import { MaintenanceCss } from "./Styles/form.css";

const MaintenanceArea = () => {
  const translate = useTranslate();
  const maintenanceCss = MaintenanceCss();
  return (
    <div className={maintenanceCss.maintenanceArea}>
      <BooleanInput
        source="maintenance_enabled"
        defaultValue={false}
        label={translate("resources.connection.fields.maintenance_enabled")}
      />
      <ConditionalInput validate={(values: any) => values.maintenance_enabled === true}>
        <span className="MuiTypography-body1">
          {translate("resources.connection.fields.maintenance_note_utc")}
        </span>
        <ArrayInput source="maintenance_schedules" label={null} validate={required()}>
          <SimpleFormIterator>
            <TextInput
              className={maintenanceCss.scheduleInput}
              source="start_schedule"
              validate={[required(), scheduleValidation]}
              label={translate("resources.connection.fields.mainetance_start_schedule_expression")}
            />
            <TextInput
              className={maintenanceCss.scheduleInput}
              source="end_schedule"
              validate={[required(), scheduleValidation]}
              label={translate("resources.connection.fields.mainetance_end_schedule_expression")}
            />
          </SimpleFormIterator>
        </ArrayInput>
      </ConditionalInput>
    </div>
  );
};

export default MaintenanceArea;
