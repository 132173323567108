import {
  useTranslate,
  TextInput,
  required,
  TextField,
  Labeled,
  NumberInput,
  NumberField,
} from "react-admin";
import { generateValidateArn } from "../..";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";

const PARAMETERS = ["bucketName", "roleARN", "roleSessionDurationInSec", "region"];

const Inputs = ({ classes }: { classes: ClassNameMap<"field"> }) => {
  const translate = useTranslate();

  const validRoleArn = generateValidateArn(/^arn:aws:iam::\d{12}:role\/[a-zA-Z0-9_-]{1,64}$/);
  return (
    <>
      <TextInput
        className={classes.field}
        source="parameters.bucketName"
        label={translate("resources.connection.fields.bucketName")}
        validate={[required()]}
      />
      <TextInput
        className={classes.field}
        source="parameters.region"
        label={translate("resources.connection.fields.region")}
        validate={[required()]}
      />
      <TextInput
        className={classes.field}
        source="parameters.roleARN"
        label={translate("resources.connection.fields.roleARN")}
        validate={[validRoleArn]}
      />
      <NumberInput
        className={classes.field}
        source="parameters.roleSessionDurationInSec"
        label={translate("resources.connection.fields.roleSessionDurationInSec")}
      />
    </>
  );
};

const Fields = ({ classes }: { classes: ClassNameMap<"field"> }) => {
  const translate = useTranslate();

  return (
    <>
      <Labeled
        source="parameters.bucketName"
        label={translate("resources.connection.fields.bucketName")}
        className={classes.field}
      >
        <TextField source="parameters.bucketName" />
      </Labeled>
      <Labeled
        source="parameters.region"
        label={translate("resources.connection.fields.region")}
        className={classes.field}
      >
        <TextField source="parameters.region" />
      </Labeled>
      <Labeled
        source="parameters.roleARN"
        label={translate("resources.connection.fields.roleARN")}
        className={classes.field}
      >
        <TextField source="parameters.roleARN" />
      </Labeled>
      <Labeled
        source="parameters.roleSessionDurationInSec"
        label={translate("resources.connection.fields.roleSessionDurationInSec")}
        className={classes.field}
      >
        <NumberField source="parameters.roleSessionDurationInSec" />
      </Labeled>
    </>
  );
};

export { Inputs, Fields, PARAMETERS };
