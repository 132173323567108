import { makeStyles } from "@material-ui/core/styles";
const Styles = makeStyles(() => ({
  sourceCSS: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    width: "100%"
  },
}));

export { Styles };
