import { Button, ButtonProps } from "react-admin";
import DownloadIcon from "@mui/icons-material/Download";
import { ReactElement } from "react";
import { Record } from "ra-core";
import API from "@aws-amplify/api-rest";

interface Props {
  icon?: ReactElement;
  record?: Record;
}

type DownloadFileActionProps = Props & ButtonProps;

const triggerDownload = (fileName: string, url: string) => {
  let evt = new MouseEvent("click", {
    view: window,
    bubbles: true,
    cancelable: true,
  });

  let link = document.createElement("a");
  link.download = fileName;
  link.href = url;
  link.style.display = "none";
  document.body.appendChild(link);
  link.dispatchEvent(evt);
  document.body.removeChild(link);
};

const DownloadFileAction = (props: DownloadFileActionProps) => {
  const { icon = <DownloadIcon />, record, ...rest } = props;
  const downloadLinkClick = () => {
    if (record) {
      const { executionCorrelationId, fileName, configurationId } = record;
      API.get("api", "/tracking/download", {
        queryStringParameters: {
          correlationId: executionCorrelationId,
          fileName: fileName,
          configurationId: configurationId,
        },
      }).then((url) => {
        triggerDownload(fileName, url);
      });
    }
  };

  return (
    <Button onClick={() => downloadLinkClick()} {...(rest as any)} align="center">
      {icon}
    </Button>
  );
};

export default DownloadFileAction;
