import {
  SaveButton,
  Toolbar,
} from "react-admin";

import EditBase from "../BaseModel/Edit";

const UpdateToolbar = (props: any) => (
  <Toolbar {...props}>
    <SaveButton/>
  </Toolbar>
);

const EditForm = (props: any) => {
  return (
    <EditBase {...props} toolbar={<UpdateToolbar />}></EditBase>
  );
};

export default EditForm;
