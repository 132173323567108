import { ConditionalInput } from "@/Components/ConditionalForm";
import {
    BooleanInput,
    required,
    TextInput,
    useTranslate
} from "react-admin";
import { Styles } from "./Styles/retry.css";
import {InputLabel} from "@mui/material";


const Retry = () => {
    const translate = useTranslate();
    const style = Styles();
    return (<div className={style.retryCSS}>
        <BooleanInput source="retry.retryAutoSurcharge" defaultValue={false} label={translate("resources.configuration.fields.retry")} />
        <ConditionalInput validate={(values: any) => values.retry.retryAutoSurcharge === true}>
            <InputLabel>{translate("resources.configuration.fields.retry_auto_frequence")}</InputLabel>
            <TextInput
                className={style.scheduleInput}
                defaultValue={3}
                source="retry.retryLimit"
                validate={[required()]}
                label={translate("resources.configuration.fields.retry_auto_frequence")
            }
            />
            <InputLabel>{translate("resources.configuration.fields.retry_auto_ecart")}</InputLabel>
            <TextInput
                className={style.scheduleInput}
                defaultValue={3000}
                source="retry.retryDelay"
                validate={[required()]}
                label={translate("resources.configuration.fields.retry_auto_ecart")}
            />
        </ConditionalInput>
    </div>);
}

export default Retry;
