import { Labeled } from "react-admin";
import { JsonField, JsonInput } from "react-admin-json-view";

const JSONParameters = {
  addLabel: true,
  jsonString: false,
  reactJsonOptions: {
    name: null,
    collapsed: true,
    enableClipboard: false,
    displayDataTypes: false,
  },
};

const JSONField = (props: any) => (
  <Labeled source={props.source} label={props.label}>
    <JsonField {...props} {...JSONParameters} />
  </Labeled>
);
const JSONInput = (props: any) => <JsonInput {...props} {...JSONParameters} />;

export { JSONField, JSONInput };
