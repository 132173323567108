import {
  BooleanInput,
  ReferenceInput,
  required,
  SelectInput,
  TextInput,
  Translate,
  useInput,
  useTranslate,
} from "react-admin";
import { useCallback, useEffect, useState } from "react";
import { ConditionalInput } from "@/Components/ConditionalForm";
import API from "@aws-amplify/api-rest";
import { useForm } from "react-final-form";
type IntegrationProps = {
  source: string;
  label: string;
};

const IntegrationTarget = ({ source, label }: IntegrationProps) => {
  const translate = useTranslate();

  const form = useForm();
  const { id } = useInput({ source });
  const [useIntegration, setIntegration] = useState(false);

  //Reset integration
  const resetIntegrationTemplate = () => {
    form.change(`${id}.template`, "");
    form.change(`${id}.type`, "");
    form.change(`${id}.parameters.path`, "");
    form.change(`${id}.parameters.connection`, "");
    form.change(`${id}.parameters.file_name`, "");
    form.change(`${id}.parameters.content_pattern`, "");
    form.change(`${id}.parameters.use_target_connection`, false);
  };

  const toggleIntegration = (event: boolean) => {
    setIntegration(event);
    resetIntegrationTemplate();
  };

  useEffect(() => {
    let currentIndex = parseInt(id[8]);
    let values = form.getState().values;
    let integration = values.targets[currentIndex].integration;

    setIntegration(integration.use_integration);
  }, [id, form]);

  return (
    <>
      <BooleanInput
        label={label}
        source={`${id}.use_integration`}
        defaultValue={useIntegration}
        onChange={toggleIntegration}
      />
      <ConditionalInput
        validate={(values: any) => {
          let targetIndex = id[8];
          if (!values || !values.targets) return false;
          if (values.targets.length <= parseInt(targetIndex)) return false;
          return values.targets[parseInt(targetIndex)].integration.use_integration === true;
        }}
      >
        <ReferenceInput
          source={`${id}.type`}
          reference="integrationType"
          label={translate("resources.configuration.fields.target.integrationType")}
          validate={required()}
          sort={{ field: "name", order: "ASC" }}
        >
          <SelectInput optionText="name" resettable={true} style={integrationInputStyle} />
        </ReferenceInput>

        <br></br>

        <ConditionalInput
          validate={(values: any) => {
            let targetIndex = id[8];
            if (!values || !values.targets) return false;
            if (values.targets.length <= parseInt(targetIndex)) return false;
            return values.targets[parseInt(targetIndex)].integration.type === "WRITE_FILE";
          }}
        >
          <WriteFileIntegration id={id} translate={translate} />
        </ConditionalInput>
        <ConditionalInput
          validate={(values: any) => {
            let targetIndex = id[8];
            if (!values || !values.targets) return false;
            if (values.targets.length <= parseInt(targetIndex)) return false;
            return values.targets[parseInt(targetIndex)].integration.type === "START_FLOW";
          }}
        >
          <StartFlowIntegration id={id} translate={translate} />
        </ConditionalInput>
      </ConditionalInput>
    </>
  );
};

const WriteFileIntegration = ({ id, translate }: { id: string; translate: Translate }) => {
  const form = useForm();
  const [integrationTemplateConnection, setIntegrationTemplateConnection] = useState("");
  const [integrationTemplateId, setIntegrationTemplateId] = useState("");

  const prefill = useCallback(
    (template: any) => {
      form.change(`${id}.type`, template.type);
      form.change(`${id}.parameters.path`, template.parameters?.path);
      form.change(`${id}.parameters.connection`, template.parameters?.connection);
      form.change(`${id}.parameters.file_name`, template.parameters?.file_name);
      form.change(`${id}.parameters.content_pattern`, template.parameters?.content_pattern);
      form.change(
        `${id}.parameters.use_target_connection`,
        template.parameters?.use_target_connection ?? false
      );
    },
    [form, id]
  );

  const clearIntegrationValues = () => {
    form.change(`${id}.parameters.path`, "");
    form.change(`${id}.parameters.connection`, "");
    form.change(`${id}.parameters.file_name`, "");
    form.change(`${id}.parameters.content_pattern`, "");
    form.change(`${id}.parameters.use_target_connection`, false);
  };

  const getIntegrationTemplate = async (event: any) => {
    clearIntegrationValues();
    if (event === "") return;

    let integrationId: string = event.target.value;
    let json = await getIntegrationDetails(integrationId);
    prefill(json);
  };

  const getIntegrationDetails = (integrationId: string) => {
    if (!integrationId) {
      return;
    }
    return API.get("api", `/integrationConfiguration/${integrationId}`, {}).then((json) => {
      setIntegrationTemplateId(json.id);
      setIntegrationTemplateConnection(json.parameters.connection);
      return json;
    });
  };

  const toggleConnection = (event: boolean) => {
    if (event) {
      form.change(`${id}.parameters.connection`, "");
      return;
    }
    form.change(`${id}.parameters.connection`, integrationTemplateConnection);
  };

  useEffect(() => {
    let currentIndex = parseInt(id[8]);
    let values = form.getState().values;
    let integration = values.targets[currentIndex].integration;

    if (integration.use_integration) {
      getIntegrationDetails(integration.template);
      prefill(integration);
    }
  }, [id, form, prefill]);

  return (
    <div style={integrationStyle}>
      <ReferenceInput
        source={`${id}.template`}
        reference="integrationConfiguration"
        label={translate("resources.configuration.fields.target.integrationTemplate")}
        validate={required()}
        onChange={getIntegrationTemplate}
        sort={{ field: "name", order: "ASC" }}
      >
        <SelectInput
          defaultValue={integrationTemplateId}
          optionText="name"
          resettable={true}
          style={integrationInputStyle}
        />
      </ReferenceInput>
      <TextInput
        source={`${id}.parameters.path`}
        label={translate("resources.configuration.fields.target.integrationPath")}
        validate={required()}
        style={integrationInputStyle}
      />
      <TextInput
        source={`${id}.parameters.file_name`}
        label={translate("resources.configuration.fields.target.integrationFilename")}
        validate={required()}
        style={integrationInputStyle}
      />
      <TextInput
        source={`${id}.parameters.content_pattern`}
        label={translate("resources.configuration.fields.target.integrationContentPattern")}
        validate={required()}
        style={integrationInputStyle}
      />

      <BooleanInput
        defaultValue={false}
        source={`${id}.parameters.use_target_connection`}
        onChange={toggleConnection}
        label={translate("resources.configuration.fields.target.integrationUseCurrentConnection")}
      />

      <ReferenceInput
        source={`${id}.parameters.connection`}
        reference="connection"
        label={translate("resources.configuration.fields.target.integrationConnection")}
        sort={{ field: "name", order: "ASC" }}
      >
        <SelectInput optionText="name" style={integrationInputStyle} disabled />
      </ReferenceInput>
    </div>
  );
};

const StartFlowIntegration = ({ id, translate }: { id: string; translate: Translate }) => {
  const form = useForm();

  useEffect(() => {
    const clearIntegrationValues = () => {
      form.change(`${id}.template`, undefined);
      form.change(`${id}.parameters.path`, undefined);
      form.change(`${id}.parameters.connection`, undefined);
      form.change(`${id}.parameters.file_name`, undefined);
      form.change(`${id}.parameters.content_pattern`, undefined);
      form.change(`${id}.parameters.use_target_connection`, undefined);
    };

    clearIntegrationValues();
  }, [form, id]);

  return (
    <div style={integrationStyle}>
      <TextInput
        source={`${id}.parameters.flow_id`}
        label={translate("resources.configuration.fields.id")}
        validate={required()}
        style={integrationInputStyle}
      />
    </div>
  );
};

const integrationStyle = {
  width: "50%",
  display: "flex",
  flexFlow: "column nowrap",
  alignItems: "flex-start",
  justifyContent: "center",
  borderLeft: "1px solid black",
  borderBottom: "1px solid black",
  borderBottomLeftRadius: "15px",
  paddingLeft: "1rem",
};
const integrationInputStyle = {
  minWidth: "43%",
};
export default IntegrationTarget;
