import {
  useTranslate,
  SelectInput,
  TextInput,
  useListContext,
  ReferenceInput,
  CreateButton,
  ExportButton,
  NullableBooleanInput,
  usePermissions,
  Loading,
} from "react-admin";
import { Form } from "react-final-form";
import { Box, Button } from "@material-ui/core";

import SearchBarCss from "../SearchBar/Styles/SearchBar.css";
import { v4 as uuid } from "uuid";
import { authorizeAction } from "@/Settings/roles";
import { useCallback, useEffect } from "react";
import { useSearchParams } from "@/Tools/hooks";
import { toUpperCase } from "@/Tools/helpers";

export let showActionsButton = true;

export const setShowActionsButton = (action: boolean = false) => {
  showActionsButton = action;
};

const CustomSearchBarForm = (props: any) => {
  const searchParams = useSearchParams();
  const fromRef = searchParams.get("from");
  const { loading, permissions } = usePermissions();
  const searchBarCss = SearchBarCss();
  const translate = useTranslate();
  const { displayedFilters, filterValues, setFilters } = useListContext();

  const onSubmit = (values: any) => {
    values.searchId = uuid();
    if (values.targets && Object.keys(values.targets).length === 0) {
      delete values.targets;
    }
    localStorage.setItem("mft-admin-configuration-filters", JSON.stringify(values));
    setFilters(values, displayedFilters);
  };
  const sortable = { field: "name", order: "ASC" };

  const getSavedFilters = useCallback(() => {
    let item = localStorage.getItem("mft-admin-configuration-filters");
    if (item) {
      return JSON.parse(item);
    }
    return;
  }, []);

  const updateFilters = useCallback((filters) => {
    setFilters(filters, displayedFilters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (fromRef) {
      let savedFilters = getSavedFilters();
      if (savedFilters) updateFilters(savedFilters);
    }
  }, [getSavedFilters, updateFilters, fromRef]);

  return loading ? (
    <Loading />
  ) : (
    <Box width="100%">
      <div className={searchBarCss.externalModule}>
        {authorizeAction(permissions, props.resource, "create") && <CreateButton />}
        <ExportButton />
      </div>
      <div className={searchBarCss.searchForm}>
        <Form onSubmit={onSubmit} initialValues={filterValues}>
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <TextInput
                resettable
                className={searchBarCss.searchFormText}
                helperText={false}
                source="name"
                parse={toUpperCase}
                label={translate("search.filter.name")}
              />
              <ReferenceInput
                source="source.application"
                reference="application"
                className={searchBarCss.searchFormSelect}
                label={translate("resources.configuration.fields.source_application")}
                allowEmpty={true}
                sort={sortable}
              >
                <SelectInput optionText="name" />
              </ReferenceInput>
              <ReferenceInput
                source="source.connection"
                reference="connection"
                className={searchBarCss.searchFormSelect}
                label={translate("resources.configuration.fields.source_connection_list")}
                allowEmpty={true}
                sort={sortable}
              >
                <SelectInput optionText="name" />
              </ReferenceInput>
              <NullableBooleanInput
                className={searchBarCss.searchFormSelect}
                helperText={false}
                source="enabled"
              />
              <br />
              <TextInput
                resettable
                className={searchBarCss.searchFormText}
                helperText={false}
                source="code"
                parse={toUpperCase}
                label={translate("search.filter.code")}
              />
              <ReferenceInput
                source="targets.application"
                reference="application"
                className={searchBarCss.searchFormSelect}
                label={translate("resources.configuration.fields.target_application")}
                allowEmpty={true}
                sort={sortable}
              >
                <SelectInput optionText="name" />
              </ReferenceInput>
              <ReferenceInput
                source="targets.connection"
                reference="connection"
                className={searchBarCss.searchFormSelect}
                label={translate("resources.configuration.fields.target_connection_list")}
                allowEmpty={true}
                sort={sortable}
              >
                <SelectInput optionText="name" />
              </ReferenceInput>
              <Button
                className={searchBarCss.searchFormSubmit}
                variant="contained"
                color="primary"
                type="submit"
                size="large"
              >
                {translate("resources.tracking.searchForm.submitButton")}
              </Button>
            </form>
          )}
        </Form>
      </div>
    </Box>
  );
};

export default CustomSearchBarForm;
