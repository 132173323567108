import {
  DateField,
  ReferenceField,
  RichTextField,
  Show,
  SimpleShowLayout,
  TextField,
} from "react-admin";
import { ShowActionsTopToolbar } from "..";

const ShowForm = (props: any) => {
  return (
    <Show actions={<ShowActionsTopToolbar roles={props.options.roles} />} {...props}>
      <SimpleShowLayout>
        <TextField source="username" />
        <RichTextField source="description" />
        <ReferenceField source="auth_type" reference="authType" link="show">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField source="created_by" reference="user" link="show">
          <TextField source="name" />
        </ReferenceField>
        <DateField source="created_at" showTime={true} />
        <ReferenceField source="updated_by" reference="user" link="show">
          <TextField source="name" />
        </ReferenceField>
        <DateField source="updated_at" showTime={true} />
      </SimpleShowLayout>
    </Show>
  );
};

export default ShowForm;
