import Icon from "@material-ui/icons/ArrowDownward";
import List from "./List";
import Edit from "./Edit";
import Create from "./Create";
import Show from "./Show";

const IntegrationConfiguration = {
  list: List,
  show: Show,
  create: Create,
  edit: Edit,
  icon: Icon,
};

export default IntegrationConfiguration;
