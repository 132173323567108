import { EmailField } from "react-admin";
import ListBase from "../BaseModel/List";

const ListForm = (props: any) => (
  <ListBase {...props}>
    <EmailField source="email" />
  </ListBase>
);

export default ListForm;
