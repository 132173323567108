import API from "@aws-amplify/api-rest";
import { stringify } from "query-string";

export function cleanText(text: string): string {
  if (!text) return text;
  return text.replace(",", ";");
}

export async function getConnections(ids: string[]) {
  if (!ids.length) return {};
  const query = {
    filter: JSON.stringify({
      id: ids,
    }),
  };
  let connections = await API.get("api", `/connection?${stringify(query)}`, {});
  return connections.Items.map((item: any) => {
    return { [item.id]: item.name };
  }).reduce((prev: any, next: any) => {
    return Object.assign(prev, next);
  }, {});
}

export async function getUsers(ids: string[]) {
  if (!ids.length) return {};
  const query = {
    filter: JSON.stringify({
      id: ids,
    }),
  };
  let users = await API.get("api", `/user?${stringify(query)}`, {});
  return users.Items.map((item: any) => {
    return { [item.id]: item.name };
  }).reduce((prev: any, next: any) => {
    return Object.assign(prev, next);
  }, {});
}

export async function getRoles(ids: string[]) {
  if (!ids.length) return {};
}
