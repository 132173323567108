import { makeStyles } from '@material-ui/core/styles';
const MaintenanceCss = makeStyles(theme => ({
  maintenanceArea: {
    border: theme.palette.type === 'dark' ? '1px solid #575757' : '1px solid #e9e9e9',
    padding: "24px 24px 0 25px;",
    borderRadius: "13px",
    width: "auto",
  },
  scheduleInput: {
    marginLeft: '12px',
  }
}));

export {MaintenanceCss}