import { DateField, TextField, useRecordContext } from "react-admin";
import ListBase from "../BaseModel/List";
import { Alert } from "@mui/material";

const AlertField = (props: any) => {
  const { source } = props;
  const record = useRecordContext(props);
  return <Alert severity={record[source] ? "warning" : "success"} />;
};

const ListForm = (props: any) => {
  return (
    <ListBase {...props} title={"Softlocks"}>
      <TextField source="mode" />
      <DateField source="start_date" showTime={true} />
      <AlertField source="alert" />
    </ListBase>
  );
};

export default ListForm;
