import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import { Radio, FormControlLabel } from "@mui/material";
import { useTranslate } from "react-admin";

export interface SelectEnvDialogProps {
  open: boolean;
  selectedValue: string;
  onClose: (value: string) => void;
  handleListItemClick: (value: string) => void;
  validate: () => void;
}

const isProd = process.env.REACT_APP_API_URL?.startsWith(
  "https://console.mft.data.arkemacloud.com"
);
const isQual = process.env.REACT_APP_API_URL?.includes("qual");

const DEV = "dev";
const QUAL = "qual";
const PROD = "prod";

let envs: string[] = [];
if (isProd) {
  envs.push(QUAL);
  envs.push(DEV);
} else if (isQual) {
  envs.push(PROD);
  envs.push(DEV);
} else {
  envs.push(QUAL);
}

const SelectEnvDialog = (props: SelectEnvDialogProps) => {
  const { onClose, selectedValue, open, handleListItemClick, validate } = props;
  const translate = useTranslate();

  const handleClose = () => {
    onClose(selectedValue);
  };

  const OnListItemClick = (value: string) => {
    handleListItemClick(value);
  };

  const OnValidate = () => {
    validate();
    onClose("true");
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>Copy to another env </DialogTitle>
      <List sx={{ pt: 0 }}>
        {envs.map((env) => (
          <ListItem button onClick={() => OnListItemClick(env)} key={env}>
            <FormControlLabel control={<Radio />} label={env} />
          </ListItem>
        ))}
      </List>
      <Button onClick={() => OnValidate()}>{translate("common.actions.enable.title")}</Button>
    </Dialog>
  );
};

export default SelectEnvDialog;
