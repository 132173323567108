import { REFRESH_VIEW } from "react-admin";

const resetCacheReducer = (previousState = null, { type }: { type: string }) => {
  if (type === REFRESH_VIEW) {
    localStorage.setItem("api.refresh", true.toString());
  }
  return previousState;
};

export default resetCacheReducer;
