import { CRUD_GET_LIST_SUCCESS } from "react-admin";

const nextTokenReducer = (
  previousState = null,
  { type, payload }: { type: string; payload: any }
) => {
  if (type === CRUD_GET_LIST_SUCCESS) {
    return payload.nextToken ?? null;
  }
  return previousState;
};

export default nextTokenReducer;
