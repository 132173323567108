import { Fragment, useState } from "react";
import {
  Button,
  Confirm,
  useRefresh,
  useNotify,
  useUnselectAll,
  useMutation,
} from "react-admin";
import EnableIcon from "@material-ui/icons/Done";
import DisableIcon from "@material-ui/icons/Cancel";
import * as React from "react";
import SelectEnvDialog from "./SelectEnvDialog";

const CustomEnableButton = ({
  selectedIds,
  resource,
}: {
  selectedIds: string[];
  resource: string;
}) => {
  const [open, setOpen] = useState(false);
  const [mode, setMode] = useState(false);
  const refresh = useRefresh();
  const notify = useNotify();
  const unselectAll = useUnselectAll();
  const [openEnv, setOpenEnv] = React.useState(false);
  const [envValue, setEnvValue] = React.useState("");

  const [updateElements, { loading }] = useMutation(
    {
      type: "enable",
      resource: resource,
      payload: {
        ids: selectedIds,
        enabled: mode,
      },
    },
    {
      onSuccess: () => {
        refresh(true);
        notify("common.actions.enable.success", { type: "info", messageArgs: { resource } });
        unselectAll(resource);
      },
      onFailure: () =>
        notify("common.actions.enable.failure", { type: "warning", messageArgs: { resource } }),
    }
  );
  const [configurations] = useMutation(
    {
      type: "getOneWithEnv",
      resource: resource,
      payload: {
        id: selectedIds,
        env: envValue,
      },
    },
    {
      onSuccess: (data) => {
        refresh(true);
        notify("common.actions.copyToEnv.success", { type: "info", messageArgs: { resource } });
        unselectAll(resource);
      },
      onFailure: (error) => {
        let detail;
        if (error.response instanceof Object) {
          detail = error.response.data.detail;
        } else {
          detail = "unkown error"
        }
        notify("common.actions.copyToEnv.failure", { type: "warning", messageArgs: { error: detail } });
      },
    }
  );

  const handleClick = (enable: boolean) => {
    setMode(enable);
    setOpen(true);
  };
  const handleDialogClose = () => {
    setMode(false);
    setOpen(false);
  };

  const handleConfirm = () => {
    updateElements();
    setOpen(false);
  };

  const handleClickOpenEnv = () => {
    setOpenEnv(true);
  };
  const handleClickEnvDialogClose = () => {
    setOpenEnv(false);
  };

  const OnListItemClick = (value: any) => {
    setEnvValue(value);
  };
  const validateEnv = () => {
    if (envValue !== "") {
      configurations();
    }
  };

  return (
    <Fragment>
      <Button label="common.actions.enable.btn.enabled" onClick={() => handleClick(true)}>
        <EnableIcon />
      </Button>
      <Button label="common.actions.enable.btn.disabled" onClick={() => handleClick(false)}>
        <DisableIcon />
      </Button>
      <Button label="Copy to" onClick={() => handleClickOpenEnv()}>
        <DisableIcon />
      </Button>

      <Confirm
        isOpen={open}
        loading={loading}
        title="common.actions.enable.title"
        content="common.actions.enable.content"
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
      />

      <SelectEnvDialog
        selectedValue={"env"}
        open={openEnv}
        onClose={handleClickEnvDialogClose}
        handleListItemClick={OnListItemClick}
        validate={validateEnv}
      />
    </Fragment>
  );
};

export default CustomEnableButton;
