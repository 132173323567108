import { Edit, required, SimpleForm, TextInput } from "react-admin";
import RichTextInput from "ra-input-rich-text";
import { clearStyleFromHtml } from "@/Tools/helpers";

const EditForm = (props: any) => (
  <Edit {...props}>
    <SimpleForm warnWhenUnsavedChanges toolbar={props.toolbar} validate={props.validate}>
      <TextInput source="name" disabled={true} />
      <RichTextInput source="description" validate={[required()]} parse={clearStyleFromHtml} />
      {props.children}
    </SimpleForm>
  </Edit>
);

export default EditForm;
