import { SaveButton, Toolbar, SimpleForm, TextInput, Edit, EmailField } from "react-admin";
import RoleInput from "./RoleInput";
import RoleAppInput from "./RoleAppInput";

const UpdateToolbar = (props: any) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

const EditForm = (props: any) => {
  return (
    <Edit {...props}>
      <SimpleForm warnWhenUnsavedChanges toolbar={<UpdateToolbar />} validate={props.validate}>
        <TextInput source="name" disabled={true} />
        <EmailField source="email" />
        <RoleInput source="roles" label="resources.user.fields.roles" />
        <RoleAppInput source="roles" label="resources.user.fields.roles" />
      </SimpleForm>
    </Edit>

  );
};

export default EditForm;
