import { Show, SimpleShowLayout, TextField, RichTextField } from "react-admin";
import { ShowActionsTopToolbar } from "..";

const ShowForm = (props: any) => {
  return (
  <Show actions={<ShowActionsTopToolbar roles={props.options.roles} />} {...props}>
    <SimpleShowLayout>
      <TextField source="name" />
      {props.resource !== "user" && <RichTextField source="description" />}
      {props.children}
    </SimpleShowLayout>
  </Show>
);}

export default ShowForm;
