import { Button, ButtonProps } from "react-admin";
import { ReactElement } from "react";
import { Record } from "ra-core";
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser';


interface Props {
  basePath?: string;
  icon?: ReactElement;
  label?: string;
  record?: Record;
  scrollToTop?: boolean;
}

type OpenWindowButtonProps = Props & ButtonProps;

const OpenWindowButton = (props: OpenWindowButtonProps) => {
  const { icon = <OpenInBrowserIcon />, label = "Overview", record, ...rest } = props;

  const openWindow = () => {
    const url = "/#" + rest?.basePath  + "/" + record?.id + "/overview";
    const w = 880, h = 600, left = 0, tops = 0;
    const popupWindow = window.open(url, '', 'toolbar=no, location=yes, directories=no, status=no, menubar=no, scrollbars=no, resizable=1, copyhistory=no, width='+w+', height='+h+', top='+tops+', left='+left);
    if (popupWindow) {
      popupWindow.focus();
    }
  }

  return (
    <Button label={label} onClick={() => openWindow()}>
      {icon}
    </Button>
  );
};

export default OpenWindowButton;
