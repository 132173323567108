import { ArrayField, EmailField, Labeled, SingleFieldList } from "react-admin";
import BaseModel from "../BaseModel/Show";
import { UserTitle } from ".";
import SimpleChipField from "./SimpleChipField";

const ShowForm = (props: any) => (
  <BaseModel {...props} title={<UserTitle />}>
    <EmailField source="email" />
    <Labeled label="resources.user.fields.roles">
        <ArrayField source="roles">
          <SingleFieldList>
            <SimpleChipField />
          </SingleFieldList>
        </ArrayField>
      </Labeled>
  </BaseModel>
);

export default ShowForm;
