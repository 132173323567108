import * as React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import classnames from "classnames";
import {
  useTranslate,
  DashboardMenuItem,
  MenuItemLink,
  MenuProps,
  ReduxState,
  usePermissions,
} from "react-admin";
import SettingsIcon from "@material-ui/icons/Settings";

import SubMenu from "./SubMenu";
import { AppState } from "@/types";

import { RootMenuModels, ConfigMenuModels } from "@/Components/Models";
import { accessMenu } from "@/Settings/roles";

type MenuName = "menuConfiguration";

const Menu = ({ dense = false }: MenuProps) => {
  const { loading, permissions } = usePermissions();
  const [state, setState] = useState({
    menuConfiguration: true,
  });
  const translate = useTranslate();
  const open = useSelector((state: ReduxState) => state.admin.ui.sidebarOpen);
  useSelector((state: AppState) => state.theme); // force rerender on theme change
  const classes = useStyles();

  const handleToggle = (menu: MenuName) => {
    setState((state) => ({ ...state, [menu]: !state[menu] }));
  };

  return loading ? (
    <p>Loading</p> //waiting for permissions to be loaded in app context
  ) : (
    <div
      className={classnames(classes.root, {
        [classes.open]: open,
        [classes.closed]: !open,
      })}
    >
      {" "}
      <DashboardMenuItem />
      {Object.keys(RootMenuModels).map((key) =>
        accessMenu(permissions, key) ? (
          <MenuItemLink
            key={key}
            to={{
              pathname: `/${key}`,
              state: { _scrollToTop: true },
            }}
            primaryText={translate(`resources.${key}.name`)}
            leftIcon={React.createElement(RootMenuModels[key].icon)}
            dense={dense}
          />
        ) : null
      )}
      {accessMenu(permissions, "settings") ? (
        <SubMenu
          handleToggle={() => handleToggle("menuConfiguration")}
          isOpen={state.menuConfiguration}
          name="pos.settings"
          icon={<SettingsIcon />}
          dense={dense}
        >
          {Object.keys(ConfigMenuModels).map((key) => (
            <MenuItemLink
              key={key}
              to={{
                pathname: `/${key}`,
                state: { _scrollToTop: true },
              }}
              primaryText={translate(`resources.${key}.name`)}
              leftIcon={React.createElement(ConfigMenuModels[key].icon)}
              dense={dense}
            />
          ))}
        </SubMenu>
      ) : null}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  open: {
    width: 250,
  },
  closed: {
    width: 55,
  },
}));

export default Menu;
