import API from "@aws-amplify/api-rest";
import {
  Box,
  Button,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useState } from "react";
import {
  Loading,
  ReferenceInput,
  SelectInput,
  TextInput,
  Title,
  useNotify,
  useTranslate,
} from "react-admin";
import { Form } from "react-final-form";
import SearchBarCss from "../SearchBar/Styles/SearchBar.css";

function Explore() {
  const [loading, setLoading] = useState(false);
  const [defaultConnection, setDefaultConnection] = useState();
  const [defaultPath, setDefaultPath] = useState();
  const [files, setFiles] = useState([]);
  const translate = useTranslate();
  const notify = useNotify();
  const exploreDirectory = async (values: any) => {
    const { connection, path } = values;
    setLoading(true);
    setDefaultConnection(connection);
    setDefaultPath(path);
    API.get("api", `/connection/explore/${connection}`, {
      queryStringParameters: {
        path: path,
      },
    })
      .then((data) => setFiles(data))
      .catch((error) => {
        setFiles([]);
        if (error.response && error.response.data) {
          notify(error.response.data.detail, "error");
        } else {
          notify(error.message, "error");
        }
      })
      .finally(() => setLoading(false));
  };

  const SearchBar = () => {
    const searchBarCss = SearchBarCss();
    const translate = useTranslate();
    const sortable = { field: "name", order: "ASC" };
    return (
      <Box width="100%" className={searchBarCss.searchForm}>
        <Form onSubmit={exploreDirectory}>
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <ReferenceInput
                source="connection"
                reference="connection"
                className={searchBarCss.searchFormSelect}
                sort={sortable}
                defaultValue={defaultConnection}
              >
                <SelectInput optionText="name" defaultValue={defaultConnection} />
              </ReferenceInput>
              <TextInput
                source="path"
                className={searchBarCss.searchFormText}
                defaultValue={defaultPath}
              />
              <Button
                className={searchBarCss.searchFormSubmit}
                variant="contained"
                color="primary"
                type="submit"
                size="large"
              >
                {translate("resources.tracking.searchForm.submitButton")}
              </Button>
            </form>
          )}
        </Form>
      </Box>
    );
  };

  return (
    <div>
      <Title title={"Explore Connections"} />
      <SearchBar />
      <TableContainer>
        <TableHead>
          <TableRow>
            <TableCell>{translate("common.actions.explore.name")}</TableCell>
            <TableCell>{translate("common.actions.explore.type")}</TableCell>
            <TableCell>{translate("common.actions.explore.size")}</TableCell>
            <TableCell>{translate("common.actions.explore.modifiedAt")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {loading ? (
            <Loading />
          ) : (
            files.map((file: any) => {
              return (
                <TableRow>
                  <TableCell>{file.name}</TableCell>
                  <TableCell>{file.type}</TableCell>
                  <TableCell>{file.size}</TableCell>
                  <TableCell>{file.modified_at}</TableCell>
                </TableRow>
              );
            })
          )}
        </TableBody>
      </TableContainer>
    </div>
  );
}

export default Explore;
