import { JSONField } from "@/Components/CustomJson";
import BaseModel from "../BaseModel/Show";

const ShowForm = (props: any) => (
  <BaseModel {...props}>
    <JSONField source="parameters" />
  </BaseModel>
);

export default ShowForm;
