import Icon from "@material-ui/icons/ArrowDownward";
import List from "./List";
import Show from "./Show";

const IntegrationType = {
  list: List,
  show: Show,
  icon: Icon,
};

export default IntegrationType;
