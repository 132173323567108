import { useState } from "react";
import { useTranslate } from "ra-core";
import { InputAdornment, IconButton } from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { TextInputProps, TextInput } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";

export interface PasswordInputProps extends TextInputProps {
  initiallyVisible?: boolean;
}

const useStyles = makeStyles({
  custom: {
    "& textarea": {
      color: "transparent",
      textShadow: "0 0 8px rgba(0,0,0,0.5)",
    },
  },
});

const CustomPasswordInput = (props: PasswordInputProps) => {
  const { initiallyVisible = false, ...rest } = props;
  const [visible, setVisible] = useState(initiallyVisible);
  const translate = useTranslate();
  const classes = useStyles();

  const handleClick = () => {
    setVisible(!visible);
  };

  return (
    <TextInput
      {...rest}
      type={"text"}
      InputProps={{
        className: visible ? undefined : classes.custom,
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label={translate(
                visible ? "ra.input.password.toggle_visible" : "ra.input.password.toggle_hidden"
              )}
              onClick={handleClick}
            >
              {visible ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default CustomPasswordInput;
