import { stringify } from "query-string";
import { DataProvider } from "ra-core";
import API from "@aws-amplify/api-rest";

let cache: { [key: string]: any } = {};

const ApiProvider = (): DataProvider => ({
  getList: (resource, params) => {
    if (localStorage.getItem("api.refresh") === "true") {
      cache = {};
      localStorage.setItem("api.refresh", false.toString());
    }

    const { field, order } = params.sort;
    const query: {
      sort: string;
      filter: string;
      nextToken?: string;
      page?: number;
      perPage?: number;
    } = {
      sort: JSON.stringify([field, order]),
      filter: JSON.stringify(params.filter),
      nextToken: undefined,
      page: params.pagination.page,
      perPage: params.pagination.perPage,
    };
    const { page, perPage } = params.pagination;
    if (!cache[`${resource}`] || !cache[`${resource}`][`${query.sort}|${query.filter}|${perPage}`])
      cache[`${resource}`] = { [`${query.sort}|${query.filter}|${perPage}`]: {} };
    else {
      const current = cache[`${resource}`][`${query.sort}|${query.filter}|${perPage}`];
      query.nextToken = current[`${page - 1}`] ? JSON.stringify(current[`${page - 1}`]) : undefined;
    }

    const url = `/${resource}?${stringify(query)}`;
    const options = {};

    return API.get("api", url, options).then((json) => {
      const result = {
        data: json.Items,
        total: json.total || 100000,
        nextToken: json.NextToken,
      };
      cache[`${resource}`][`${query.sort}|${query.filter}|${perPage}`][`${page}`] = json.NextToken;
      return result;
    });
  },

  getOne: (resource, params) => {
    return API.get("api", `/${resource}/${params.id}`, {}).then((json) => {
      return {
        data: json,
      };
    });
  },

  getOneWithEnv: (resource: string, params: any) => {
    return API.get("api", `/${resource}/copy/${params.id}/${params.env}`, {}).then((json) => ({
      data: json,
    }));
  },

  getMany: (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    const url = `/${resource}?${stringify(query)}`;
    const options = {};

    return API.get("api", url, options).then((json) => ({ data: json.Items }));
  },

  enable: (resource: string, params: any) => {
    const url = `/${resource}/enable`;
    const options = { body: params };
    return API.post("api", url, options).then((json) => ({ data: json }));
  },

  test: async (resource: string, params: { id: string }) => {
    const url = `/${resource}/${params.id}/test`;
    const options = { response: true };

    return await API.get("api", url, options)
      .then((json) => ({ data: json }))
      .catch((error) => Promise.reject(error.response.data));
  },

  getManyReference: (resource, params) => {
    const { field, order } = params.sort;

    const query = {
      sort: JSON.stringify([field, order]),
      filter: JSON.stringify({
        ...params.filter,
        [params.target]: params.id,
      }),
    };
    const url = `/${resource}?${stringify(query)}`;
    const options = {};

    return API.get("api", url, options).then((json) => ({
      data: json.Items,
      total: json.total ?? json.Items.length,
    }));
  },

  update: (resource, params) =>
    API.put("api", `/${resource}/${params.id}`, {
      body: params.data,
    }).then((json) => ({
      data: json,
    })),

  // simple-rest doesn't handle provide an updateMany route, so we fallback to calling update n times instead
  updateMany: (resource, params) =>
    Promise.all(
      params.ids.map((id) =>
        API.put("api", `/${resource}/${id}`, {
          body: params.data,
        })
      )
    ).then((responses) => ({ data: responses.map((json) => json.id) })),

  create: (resource, params) =>
    API.post("api", `/${resource}`, {
      body: params.data,
    }).then((json) => ({
      data: { ...params.data, id: json.id },
    })),

  delete: (resource, params) =>
    API.del("api", `/${resource}/${params.id}`, {
      headers: { "Content-Type": "text/plain" },
    }).then((json) => ({
      data: json,
    })),

  // simple-rest doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
  deleteMany: (resource, params) =>
    Promise.all(
      params.ids.map((id) =>
        API.del("api", `/${resource}/${id}`, {
          headers: { "Content-Type": "text/plain" },
        })
      )
    ).then((responses) => ({
      data: responses.map((json) => json.id),
    })),

  deleteWithChecking: (resource: string, params: any) => {
    const url = `/${resource}/deleteWithChecking`;
    const options = { body: params };
    return API.post("api", url, options).then((json) => ({ data: json }));
  },
});

export default ApiProvider;
