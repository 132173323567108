import {
  BooleanInput,
  ReferenceInput,
  required,
  SaveButton,
  SelectInput,
  Toolbar,
} from "react-admin";
import CreateBase from "../BaseModel/Create";
import MaintenanceArea from "./Maintenance";
import { ParametersInputs, ParametersValidation } from "./Parameters";
import Retry from "./Retry";

const PostToolbar = (props: any) => (
  <Toolbar {...props}>
    <SaveButton
      transform={(data) => {
        const { parameters, ...rest } = data;
        return {
          ...rest,
          parameters,
        };
      }}
      submitOnEnter={false}
    />
  </Toolbar>
);

const CreateForm = (props: any) => (
  <CreateBase {...props} toolbar={<PostToolbar />} validate={ParametersValidation}>
    <BooleanInput source="enabled" defaultValue={true} />
    <ReferenceInput source="type" reference="connectionType" validate={[required()]}>
      <SelectInput optionText="name" />
    </ReferenceInput>
    <ParametersInputs source="parameters" validate={[required()]} />
    <MaintenanceArea/>
      <Retry/>
  </CreateBase>
);

export default CreateForm;
