import { Button, ButtonProps } from "react-admin";
import PageviewIcon from "@material-ui/icons/Pageview";
import { ReactElement } from "react";
import { Record } from "ra-core";
interface Props {
  icon?: ReactElement;
  record?: Record;
}

const datadogEndpoint= process.env.REACT_APP_ENV === "prod" ? "https://arkema_general_o.datadoghq.eu": "https://arkema_general_d.datadoghq.eu"

type OpenLogsActionProps = Props & ButtonProps;
const msToAdd = 3600000; // corresponding to 1 hour to add in datadog intervals

const OpenLogsAction = (props: OpenLogsActionProps) => {
  const { icon = <PageviewIcon />, record, ...rest } = props;
  const onLogsLinkClick = () => {
    // No need to specify environment thanks to the unique correlation id
    const service = "hip-mft-*";

    // files process
    if (record && record.executionCorrelationId && record?.fileStartDateTimestamp) {
      const correlationId = record!.executionCorrelationId;
      const fileName = record!.fileName;
      const toTimestamp = record!.fileEndDateTimestamp
        ? Number(record.fileEndDateTimestamp) + msToAdd
        : Date.now();
      const fromTimestamp = Number(record.fileStartDateTimestamp) - msToAdd;

      const query = encodeURI(
        `service:${service} ` +
          `(@data.file.name:"${fileName}" OR @data.file.old_name:"${fileName}" OR @data.fileName:"${fileName}" OR @extras.file.name:"${fileName}") ` +
          `(@correlation_id:${correlationId} OR @correlation_id.S:${correlationId} OR @data.correlation_id:${correlationId})`
      );
      window.open(
        `${datadogEndpoint}/logs?event&from_ts=${fromTimestamp}&index=&live=false&query=${query}&to_ts=${toTimestamp}&viz=stream`,
        "_blank"
      );

      // execution process
    } else {
      const configurationId = record!.configurationId;
      const toTimestamp = Date.now();
      const fromTimestamp = record?.firstFileExecutionTimestamp
        ? Number(record.firstFileExecutionTimestamp) - msToAdd
        : 0;

      const query = encodeURI(`service:${service} @data.configuration.id.S:"${configurationId}"`);
      window.open(
        `${datadogEndpoint}/logs?event&from_ts=${fromTimestamp}&index=&live=false&query=${query}&to_ts=${toTimestamp}&viz=stream`,
        "_blank"
      );
    }
  };

  return (
    <Button onClick={() => onLogsLinkClick()} {...(rest as any)} align="center">
      {icon}
    </Button>
  );
};

export default OpenLogsAction;
