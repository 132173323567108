import { downloadCSV } from "react-admin";
import jsonExport from "jsonexport/dist";
import { dropDuplicates, removeEmptyOrNullString } from "@/Tools/helpers";
import { cleanText, getConnections, getUsers } from "@/Tools/exporterTools";

const PollingExporter = async (records: any) => {
  const connectionIds = dropDuplicates(
    removeEmptyOrNullString([...records.map((record: any) => record.connection)])
  );
  const userIds = dropDuplicates(
    removeEmptyOrNullString([
      ...records.map((record: any) => record.created_by),
      ...records.map((record: any) => record.updated_by),
    ])
  );
  const connections = await getConnections(connectionIds);
  const users = await getUsers(userIds);
  const data = records.map((record: any) => {
    return {
      ...record,
      description: cleanText(record.description),
      connection: connections[record.connection],
      created_by: users[record.created_by] ?? record.created_by,
      updated_by: users[record.updated_by] ?? record.updated_by,
    };
  });

  return jsonExport(data, { rowDelimiter: "," }, (error: any, csv: any) => {
    if (error) console.log("Error CSV Export : ", error);
    downloadCSV(csv, `polling_export_${Date.now()}`);
  });
};

export default PollingExporter;
