import Icon from "@material-ui/icons/Search";
import List from "./List";
import Show from "./Show";

const Tracking = {
  list: List,
  icon: Icon,
  show: Show,
};

export default Tracking;
