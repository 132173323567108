import { Chip } from "@mui/material";

const style = {
  main: {
    display: "flex",
    FlexWrap: "wrap",
    marginTop: 8,
    marginBottom: 0,
  },
  chip: { margin: 4 },
};

const SimpleChipField = (props: any) => {
  const { record } = props;
  return record ? (
    <span style={style.main}>
      <Chip key={record} label={record} style={style.chip} />
    </span>
  ) : null;
};

export default SimpleChipField;
