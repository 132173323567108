import { ReactElement } from "react";
import PropTypes from "prop-types";
import RuleFolderIcon from '@mui/icons-material/RuleFolder';
import { ButtonProps as MuiButtonProps } from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import { useResourceContext } from "ra-core";
import { Button, ButtonProps } from "react-admin";


const ExploreConnectionButton = (props: ExploreConnectionButtonProps) => {
  const {
    icon = defaultIcon,
    label = "ra.action.copy",
    ...rest
  } = props;
  const resource = useResourceContext();
  return (
    <Button
      component={Link}
      to={`${resource}/explore`}
      label={label}
      onClick={stopPropagation}
      {...(rest as any)}
    >
      {icon}
    </Button>
  );
};

const defaultIcon = <RuleFolderIcon/>;

// useful to prevent click bubbling in a datagrid with rowClick
const stopPropagation = (e: { stopPropagation: () => any }) => e.stopPropagation();

interface Props {
  basePath?: string;
  icon?: ReactElement;
  label?: string;
  scrollToTop?: boolean;
}

export type ExploreConnectionButtonProps = Props & ButtonProps & MuiButtonProps;

ExploreConnectionButton.propTypes = {
  basePath: PropTypes.string,
  icon: PropTypes.element,
  label: PropTypes.string,
  scrollToTop: PropTypes.bool,
};

export default ExploreConnectionButton;
