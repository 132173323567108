import { JSONField } from "@/Components/CustomJson";
import ListBase from "../BaseModel/List";

const ListForm = (props: any) => (
  <ListBase {...props}>
    <JSONField source="parameters" />
  </ListBase>
);

export default ListForm;
