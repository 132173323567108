import { BooleanInput, ReferenceInput, required, SelectInput, TextInput } from "react-admin";
import { scheduleValidation } from "..";
import CreateBase from "../BaseModel/Create";
const sortable = {field : "name", order : "ASC"}

const CreateForm = (props: any) => (
  <CreateBase {...props}>
    <BooleanInput source="enabled" defaultValue={true} />
    <ReferenceInput source="connection" reference="connection" validate={[required()]}
                    sort={sortable}
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
    <TextInput source="schedule_expression" validate={[required(), scheduleValidation]} />
    <TextInput source="path" validate={[required()]} />
    <TextInput source="regex" />
  </CreateBase>
);

export default CreateForm;
