import Icon from "@mui/icons-material/AdminPanelSettings"
import Create from "./Create";
import Edit from "./Edit";
import List from "./List";
import Show from "./Show";

const IncomingHttpCredentials = {
  create: Create,
  edit: Edit,
  list: List,
  show: Show,
  icon: Icon,
};

export default IncomingHttpCredentials;
