import { ReferenceInput, required, SelectInput, TextInput, useTranslate } from "react-admin";
import CreateBase from "../BaseModel/Create";
import { ConditionalInput } from "@/Components/ConditionalForm";
import { Typography } from "@mui/material";

const sort = { field: "name", order: "ASC" };
const CreateForm = (props: any) => {
  const translate = useTranslate();

  const removeUselessParams = (formData: any) => {
    const WRITE_FILE_PARAMS = ["connection", "path", "file_name", "content_pattern"];
    const START_FLOW_PARAMS = ["flow_id"];
    if (
      formData.type === "WRITE_FILE" &&
      START_FLOW_PARAMS.some((param) => Object.keys(formData.parameters).includes(param))
    ) {
      const { flow_id, ...rest } = formData.parameters;
      return {
        ...formData,
        parameters: {
          ...rest,
        },
      };
    }
    if (
      formData.type === "START_FLOW" &&
      WRITE_FILE_PARAMS.some((param) => Object.keys(formData.parameters).includes(param))
    ) {
      const { connection, path, file_name, content_pattern, ...rest } = formData.parameters;
      return {
        ...formData,
        parameters: {
          ...rest,
        },
      };
    }
    return formData;
  };

  return (
    <CreateBase {...props} transform={removeUselessParams}>
      <ReferenceInput source="type" reference="integrationType" validate={[required()]}>
        <SelectInput optionText="name" />
      </ReferenceInput>
      <ConditionalInput
        validate={(values: any) => {
          if (!values || !values.type) return false;
          return values.type === "WRITE_FILE";
        }}
      >
        <ReferenceInput
          source="parameters.connection"
          reference="connection"
          validate={[required()]}
          sort={sort}
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
        <TextInput source="parameters.path" />
        <TextInput source="parameters.file_name" />
        <TextInput source="parameters.content_pattern" />
        <Typography>{translate("resources.integrationConfiguration.infoWriteFile")}</Typography>
      </ConditionalInput>
      <ConditionalInput
        validate={(values: any) => {
          if (!values || !values.type) return false;
          return values.type === "START_FLOW";
        }}
      >
        <TextInput source="parameters.flow_id" disabled />
        <Typography>{translate("resources.integrationConfiguration.infoDisabled")}</Typography>
      </ConditionalInput>
    </CreateBase>
  );
};

export default CreateForm;
