import Icon from "@material-ui/icons/ListAlt";
import List from "./List";
import Edit from "./Edit";
import Create from "./Create";
import Show from "./Show";

const Configuration = {
  list: List,
  show: Show,
  create: Create,
  edit: Edit,
  icon: Icon,
};

export default Configuration;
