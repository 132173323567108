import {
  BooleanField,
  DateField,
  NullableBooleanInput,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  TextField,
  useTranslate,
} from "react-admin";
import ListBase from "../BaseModel/List";

const ListForm = (props: any) => {
  const translate = useTranslate();
  const filters = [
    <NullableBooleanInput helperText={false} source="enabled" />,
    <ReferenceInput
      source="source.connection"
      reference="connection"
      label={translate("resources.polling.fields.connection_list")}
    >
      <SelectInput optionText="name" />
    </ReferenceInput>,
  ];

  return (
    <ListBase filters={filters} {...props}>
      <BooleanField source="enabled" sortable={false} />
      <ReferenceField source="connection" reference="connection" link="show">
        <TextField source="name" />
      </ReferenceField>
      <TextField source="schedule_expression" />
      <TextField source="regex" />
      <TextField source="path" />
      <ReferenceField source="created_by" reference="user" link="show">
        <TextField source="name" />
      </ReferenceField>
      <DateField source="created_at" showTime={true} />
      <ReferenceField source="updated_by" reference="user" link="show">
        <TextField source="name" />
      </ReferenceField>
      <DateField source="updated_at" showTime={true} />
    </ListBase>
  );
};

export default ListForm;
