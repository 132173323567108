import polyglotI18nProvider from "ra-i18n-polyglot";
import englishMessages from "./en";
import frenchMessages from "./fr";

let alreadyInitialized = false;

const messagesResolver = (newLocale: string) => {
  let curLocale = localStorage.getItem("locale") || newLocale;

  if (!alreadyInitialized) {
    alreadyInitialized = true;
  } else {
    curLocale = newLocale;
  }

  localStorage.setItem("locale", curLocale);
  if (curLocale === "fr") {
    return frenchMessages;
  }
  return englishMessages;
};

const i18nProvider = polyglotI18nProvider(messagesResolver);

export default i18nProvider;
