import Icon from "@material-ui/icons/LeakAdd";
import List from "../BaseModel/List";
import Show from "../BaseModel/Show";

const ConnectionType = {
  list: List,
  show: Show,
  icon: Icon,
};

export default ConnectionType;
