import Icon from "@material-ui/icons/Person";
import List from "./List";
import Show from "./Show";
import Edit from "./Edit";

const UserTitle = (props: any) => {
  return <span>User {props.record ? `${props.record.email}` : ""}</span>;
};

const User = {
  list: List,
  show: Show,
  edit: Edit,
  icon: Icon,
};

export default User;
export { UserTitle };
