import PropTypes from "prop-types";
import SearchIcon from "@material-ui/icons/Search";
import { InputAdornment } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { TextFieldProps } from "@material-ui/core/TextField";
import { useTranslate, InputProps } from "ra-core";
import { TextInput } from "react-admin";

const useStyles = makeStyles(
  {
    input: {
      marginTop: 32,
    },
  },
  { name: "RaSearchInput" }
);

const SearchInput = (props: SearchInputProps) => {
  const { classes: classesOverride, label, ...rest } = props;
  const translate = useTranslate();
  const classes = useStyles(props);

  return (
    <TextInput
      label={label}
      resettable
      placeholder={translate("ra.action.search")}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <SearchIcon color="disabled" />
          </InputAdornment>
        ),
      }}
      className={classes.input}
      {...rest}
    />
  );
};

SearchInput.propTypes = {
  classes: PropTypes.object,
};

export type SearchInputProps = InputProps<TextFieldProps> & Omit<TextFieldProps, "helperText">;

export default SearchInput;
