import Icon from "@material-ui/icons/ReportProblem";
import List from "../BaseModel/List";
import Show from "../BaseModel/Show";

const CriticityType = {
  list: List,
  show: Show,
  icon: Icon,
};

export default CriticityType;
