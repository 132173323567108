import {
  BooleanInput,
  required,
  TextInput,
  PasswordInput,
  useTranslate,
  TextField,
  BooleanField,
  Labeled,
  NumberInput,
  NumberField,
} from "react-admin";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";

const FTPS_PARAMETERS = ["host", "user", "password", "port", "secureOptions"];
const FTP_PARAMETERS = ["host", "user", "password", "port"];

type FTPType = { classes: ClassNameMap<"field">, disableReject: boolean }

const Inputs = ({ classes, disableReject }: FTPType) => {
  const translate = useTranslate();
  return (
    <>
      <TextInput
        className={classes.field}
        source="parameters.host"
        label={translate("resources.connection.fields.host")}
        validate={[required()]}
      />
      <TextInput
        className={classes.field}
        source="parameters.user"
        label={translate("resources.connection.fields.user")}
        validate={[required()]}
      />
      <PasswordInput
        className={classes.field}
        source="parameters.password"
        label={translate("resources.connection.fields.password")}
        validate={[required()]}
      />
      <NumberInput
        className={classes.field}
        source="parameters.port"
        label={translate("resources.connection.fields.port")}
        validate={[required()]}
        defaultValue={21}
      />
      {!disableReject && 
        <BooleanInput
          className={classes.field}
          source="parameters.secureOptions.rejectUnauthorized"
          label={translate("resources.connection.fields.reject_unauthorized")}
          defaultValue={true}
        />
      }
    </>
  );
};

const Fields = ({ classes, disableReject }: FTPType) => {
  const translate = useTranslate();
  return (
    <>
      <Labeled
        source="parameters.host"
        label={translate("resources.connection.fields.host")}
        className={classes.field}
      >
        <TextField source="parameters.host" />
      </Labeled>
      <Labeled
        source="parameters.user"
        label={translate("resources.connection.fields.user")}
        className={classes.field}
      >
        <TextField source="parameters.user" />
      </Labeled>
      <Labeled
        source="parameters.port"
        label={translate("resources.connection.fields.port")}
        className={classes.field}
      >
        <NumberField source="parameters.port" />
      </Labeled>
      {!disableReject && 
        <Labeled
          source="parameters.secureOptions.rejectUnauthorized"
          label={translate("resources.connection.fields.reject_unauthorized")}
          className={classes.field}
        >
          <BooleanField source="parameters.secureOptions.rejectUnauthorized" />
        </Labeled>
      }
    </>
  );
};

export { Inputs, Fields, FTP_PARAMETERS, FTPS_PARAMETERS };
