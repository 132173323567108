import { useEffect, useState } from "react";
import { ArrayField, CheckboxGroupInput, Labeled, SingleFieldList } from "react-admin";
import API from "@aws-amplify/api-rest";

import { RoleObject } from "@/Settings/roles";
import SimpleChipField from "./SimpleChipField";

const RoleInput = (props: any) => {
  const [choices, setChoices] = useState([]);

  const removeEmpty = (inputs: (string | RoleObject)[]) => {
    return inputs.filter((input) => input !== undefined);
  };

  useEffect(() => {
    API.get("api", `/userRolesType`, {}).then((json) => {
      const res = json.Items.map((item: RoleObject) => {
        return { id: item.id, name: item.name };
      });
      setChoices(res);
    });
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Labeled label={props.label}>
        <ArrayField source="roles">
          <SingleFieldList>
            <SimpleChipField/>
          </SingleFieldList>
        </ArrayField>
      </Labeled>
      <CheckboxGroupInput
        parse={removeEmpty}
        source={props.source}
        label={"resources.user.fields.updateRoles"}
        choices={choices}
        optionValue="id"
        optionText="name"
        defaultValue={props.record.roles}
      />
    </>
  );
};

export default RoleInput;
