import { ConditionalInput } from "@/Components/ConditionalForm";
import {
  BooleanInput,
  required,
  RadioButtonGroupInput,
  TextInput,
  useTranslate,
} from "react-admin";
import { Styles } from "./styles/filters.css";
import { InputLabel } from "@mui/material";

type FilterProps = {
  disabled?: boolean;
};

const FiltersSource = ({ disabled = false }: FilterProps) => {
  const translate = useTranslate();
  const style = Styles();
  return (
    <div className={style.filtersCSS}>
      <BooleanInput
        source="source.filters_enabled"
        defaultValue={false}
        label={translate("resources.configuration.fields.source_filters")}
        disabled={disabled}
      />
      <ConditionalInput validate={(values: any) => values.source.filters_enabled === true}>
        <InputLabel>{translate("resources.configuration.fields.source_filters_update")}</InputLabel>
        <RadioButtonGroupInput
          label={translate("resources.configuration.fields.source_filters_unitType")}
          source="source.filters.unitType"
          choices={[
            { id: "days", name: "Days" },
            { id: "hours", name: "Hours" },
          ]}
          disabled={disabled}
        />
        <TextInput
          className={style.scheduleInput}
          source="source.filters.unit"
          validate={[required()]}
          label={translate("resources.configuration.fields.source_filters_unit")}
          disabled={disabled}
        />
      </ConditionalInput>
    </div>
  );
};

export default FiltersSource;
