import Icon from "@material-ui/icons/Apps";
import List from "../BaseModel/List";
import Edit from "./Edit";
import Create from "../BaseModel/Create";
import Show from "../BaseModel/Show";

const Application = {
  list: List,
  show: Show,
  create: Create,
  edit: Edit,
  icon: Icon,
};

export default Application;
