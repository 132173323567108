import { BooleanInput, NumberInput, required, useInput, useTranslate } from "react-admin";
import { ConditionalInput } from "@/Components/ConditionalForm";
import { InputLabel } from "@mui/material";
import { useForm } from "react-final-form";
type IntegrationProps = {
  source: string;
  label: string;
};

const TargetDelay = ({ source, label }: IntegrationProps) => {
  const translate = useTranslate();
  const { id } = useInput({ source });
  const form = useForm();

  const toggleInput = (event: any) => {
    if(!event) {
      form.change(`${id}.delay_at_put`, 0);
    }
  }
  return (
    <>
      <BooleanInput label={label} source={`${id}.use_delay`} defaultValue={false} onChange={toggleInput} />
      <ConditionalInput
        validate={(values: any) => {
          let targetIndex = id[8];
          if (!values || !values.targets) return false;
          if(values.targets.length <= parseInt(targetIndex)) return false;
          return values.targets[parseInt(targetIndex)].delay.use_delay === true;
        }}
      >
        <div style={integrationStyle}>
          <InputLabel>{translate("resources.configuration.fields.target.delayInfo")}</InputLabel>
          <NumberInput
            source={`${id}.delay_at_put`}
            label={translate("resources.configuration.fields.target.delayValue")}
            style={integrationInputStyle}
            validate={required()}
          />
        </div>
      </ConditionalInput>
    </>
  );
};

const integrationStyle = {
  width: "50%",
  display: "flex",
  flexFlow: "column nowrap",
  alignItems: "flex-start",
  justifyContent: "center",
  borderLeft: "1px solid black",
  borderBottom: "1px solid black",
  borderBottomLeftRadius: "15px",
  paddingLeft: "1rem",
  marginBottom: "1rem",
};
const integrationInputStyle = {
  minWidth: "33%",
};
export default TargetDelay;
