import {
  required,
  TextInput,
  PasswordInput,
  useTranslate,
  TextField,
  Labeled,
} from "react-admin";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";

const PARAMETERS = ["host", "user", "password", "port", "secureOptions"];

type HTTPType = { classes: ClassNameMap<"field"> };

const Inputs = ({ classes }: HTTPType) => {
  const translate = useTranslate();
  return (
    <>
      <TextInput
        className={classes.field}
        source="parameters.host"
        label={translate("resources.connection.fields.host")}
        validate={[required()]}
      />
      <TextInput
        className={classes.field}
        source="parameters.user"
        label={translate("resources.connection.fields.user")}
        validate={[required()]}
      />
      <PasswordInput
        className={classes.field}
        source="parameters.password"
        label={translate("resources.connection.fields.password")}
        validate={[required()]}
      />
    </>
  );
};

const Fields = ({ classes }: HTTPType) => {
  const translate = useTranslate();
  return (
    <>
      <Labeled
        source="parameters.host"
        label={translate("resources.connection.fields.host")}
        className={classes.field}
      >
        <TextField source="parameters.host" />
      </Labeled>
      <Labeled
        source="parameters.user"
        label={translate("resources.connection.fields.user")}
        className={classes.field}
      >
        <TextField source="parameters.user" />
      </Labeled>
    </>
  );
};

export { Inputs, Fields, PARAMETERS };
