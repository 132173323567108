import { DateField, TextField } from "react-admin";
import ShowBase from "../BaseModel/Show";

const ShowForm = (props: any) => {
  return (
    <ShowBase {...props}>
      <TextField source="mode" />
      <DateField source="start_date" showTime={true} />
    </ShowBase>
  );
};

export default ShowForm;
