import Icon from "@material-ui/icons/InsertLink";
import List from "./List";
import Edit from "./Edit";
import Create from "./Create";
import Show from "./Show";

const ConnectionTitle = (props: any) => {
  return <span>Connection {props.record ? `${props.record.name}` : ""}</span>;
};

const Connection = {
  list: List,
  show: Show,
  create: Create,
  edit: Edit,
  icon: Icon,
};

export default Connection;
export { ConnectionTitle };
