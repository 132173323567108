import { makeStyles } from '@material-ui/core/styles';
const SearchBarCss = makeStyles(theme => ({
    searchFormText: {
        margin: '0px 10px 0 0',
        maxWidth: '215px',
    },
    searchFormSelect: {
        margin: '0px 10px 0 0',
        minWidth: '215px',
    },
    searchFormDatePicker: {
        margin: '0px 10px 0 0',
        minWidth: '215px',
    },
    searchFormSubmit: {
        margin: '0px 10px 0 0',
        minWidth: '215px',
        padding: '11px',
        borderRadius: '9px',
    },
    searchForm: {
        margin: '12px 0 12px 0',
        display: 'block',
        padding: '15px 15px 0 15px',
        backgroundColor: theme.palette.type === 'dark' ? 'initial' : '#f4f4f4',
        borderRadius: '12px',
        border: theme.palette.type === 'dark' ? '1px solid #575757' : '1px solid #e9e9e9',
    },

    actions: {
        margin: '12px 0 12px 0',
        display: 'block',
        backgroundColor: theme.palette.type === 'dark' ? 'initial' : '#f4f4f4',
        borderRadius: '12px',
        textAlign: "center",
        border: theme.palette.type === 'dark' ? '1px solid #575757' : '1px solid #e9e9e9',
    },
    externalModule: {
        textAlign: 'left',
    }
}));

export default SearchBarCss;